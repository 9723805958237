<template>
  <div class="my-5">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md3>
            <v-card class="elevation-12" v-if="!showTwoFactorPanel">
              <v-toolbar dark dense color="primary">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
              </v-toolbar>
              <v-alert dense :value="alerterror" type="error">
                Invalid Username or Password
              </v-alert>
              <v-alert dense :value="alerterrorlogin" type="error">
                User already logged in
              </v-alert>
              <v-card-text v-if="!showTwoFactorPanel">
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="editedItem.email"
                    prepend-inner-icon="email"
                    name="login"
                    label="Email"
                    type="text"
                    outlined
                    autocomplete="off"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                  <v-text-field
                    v-model="editedItem.password"
                    id="password"
                    prepend-inner-icon="lock"
                    name="password"
                    label="Password"
                    type="password"
                    outlined
                    autocomplete="off"
                    :rules="[rules.required]"
                    v-on:keydown.enter.prevent="validate"
                  ></v-text-field>
                  <v-btn color="primary" x-large block @click="validate"
                    >Login</v-btn
                  >
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-spacer></v-spacer>
                <a href="#/ForgotPassword" class="pl-2" style="color: #fcf9f9"
                  >Forgot Password?</a
                >
              </v-card-actions>
            </v-card>
            <v-card class="elevation-12" v-else>
              <v-toolbar dark dense color="primary">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="auth" v-model="validV">
                  <v-text-field
                    v-model="authCode"
                    prepend-inner-icon="lock"
                    name="authCode"
                    label="Token"
                    type="text"
                    outlined
                    v-on:keydown.enter.prevent="validate2fa"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" x-large block @click="validate2fa"
                  >Validate</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  data: () => ({
    title: "Login",
    drawer: null,
    dialog: false,
    alerterror: false,
    alerterrorlogin: false,
    editedItem: {
      email: "",
      password: "",
    },
    status: "",
    valid: true,
    validV: true,
    authCode: "",
    showTwoFactorPanel: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  beforeMount: function () {
    let self = this;
    self.logout();
  },
  computed: {
    getUserEmail: function () {
      return localStorage.getItem("useremail");
    },
  },
  methods: {
    login() {
      let email = this.editedItem.email;
      let password = this.editedItem.password;
      this.$store
        .dispatch("login", { email, password })
        .then((response) => {
          if (response.data.auth) {
            this.showTwoFactorPanel = true;
            this.title = "Two Factor Authentication";
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401 || error.response.status == 404) {
              this.alerterror = true;
            } else if (error.response.status == 409) {
              this.alerterrorlogin = true;
            }
          }
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.login();
        this.$refs.form.resetValidation();
      }
    },
    validate2fa() {
      if (this.$refs.auth.validate()) {
        this.validateToken();
        this.$refs.auth.resetValidation();
      }
    },
    logout: function () {
      // this.$store.dispatch("logout", { email }).then(() => {
      //   // this.$router.push("/");
      //   //this.$router.push({ name: "Login" }).catch(() => {});
      // });
    },
    validateToken: function () {
      const data = {
        email: this.editedItem.email,
        token: this.authCode,
      };

      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/auth/validate-token`,
        data: data,
      })
        .then((response) => {
          if (response.data.validated) {
            this.$router.push("/Dashboard");
          }
        })
        .catch((error) => {
          this.status = error.response.data.message;
        });
    },
  },
};
</script>
