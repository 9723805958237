<template>
  <div class="my-5">
    <v-main>
      <v-container fluid fill-height>
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="8" md="4" lg="3">
            <v-card class="elevation-11" v-if="!showTwoFactorPanel">
              <v-toolbar dark dense color="primary">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text v-if="!showTwoFactorPanel">
                <v-form ref="form" v-model="valid">
                  <v-container grid-list-md>
                    <v-text-field
                      v-model="name"
                      prepend-inner-icon="person"
                      name="name"
                      label="Name"
                      type="text"
                      outlined
                      dense
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="surname"
                      prepend-inner-icon="person"
                      name="surname"
                      label="Surname"
                      type="text"
                      outlined
                      dense
                      :rules="[rules.required]"
                    ></v-text-field>

                    <v-text-field
                      v-model="email"
                      prepend-inner-icon="email"
                      name="email"
                      label="Email"
                      type="text"
                      outlined
                      readonly
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="acctype"
                      prepend-inner-icon="account_box"
                      name="acctype"
                      label="Account Type"
                      type="text"
                      outlined
                      readonly
                      dense
                    ></v-text-field>

                    <v-text-field
                      v-model="newPassword"
                      prepend-inner-icon="mdi-lock"
                      name="newPassword"
                      label="Password"
                      type="password"
                      outlined
                      dense
                      :rules="[rules.password]"
                    ></v-text-field>
                    <v-text-field
                      v-model="pwdConfirm"
                      prepend-inner-icon="mdi-lock"
                      name="pwdConfirm"
                      label="Confirm Password"
                      type="password"
                      outlined
                      dense
                      :rules="[
                        (v) =>
                          v === this.newPassword || 'Passwords do not match',
                      ]"
                    ></v-text-field>

                    <v-btn color="primary" x-large block @click="validate"
                      >Register</v-btn
                    >
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                {{ status }}
                {{ loading }}
              </v-card-actions>
            </v-card>
            <v-card class="elevation-12" v-else>
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="auth" v-model="validV">
                  <div class="d-flex justify-center ma-4">
                    <v-img :src="qr" max-height="180" max-width="180"></v-img>
                  </div>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="authCode"
                    prepend-inner-icon="lock"
                    name="authCode"
                    label="Token"
                    type="text"
                    outlined
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" x-large block @click="validate2fa"
                  >Register</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import qrcode from "qrcode";
export default {
  data: () => ({
    title: "Register User",
    valid: true,
    validV: true,
    loading: "",
    status: "",
    newPassword: "",
    pwdConfirm: "",
    userId: "",
    token: "",
    acctype: "",
    email: "",
    name: "",
    surname: "",
    authCode: "",
    qr: "",
    showTwoFactorPanel: false,
    rules: {
      required: (value) => !!value || "Required.",
      password: (value) => {
        const pattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 characters with at least one capital letter, a number and a special character."
        );
      },
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    pwdConfirms: [
      (v) => !!v || "Confirm password",
      (v) => v === this.newPassword || "Passwords do not match",
    ],
  }),
  beforeMount: function () {
    let self = this;
    if (this.$route.params.email > "") {
      this.email = this.$route.params.email;
    }
    if (this.$route.params.acctype == "SuperUser") {
      this.acctype = "Super User";
    } else {
      this.acctype = this.$route.params.acctype;
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.sendRegister();
        this.$refs.form.resetValidation();
      }
    },
    validate2fa() {
      if (this.$refs.auth.validate()) {
        this.validateToken();
        this.$refs.auth.resetValidation();
      }
    },
    sendRegister: function () {
      this.status = "";
      this.loading = "Sending registration request, please wait....";
      const data = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        acctype: this.acctype,
        password: this.newPassword,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/auth/register/${this.$route.params.email}/${this.$route.params.token}`,
        data: data,
      })
        .then((response) => {
          this.loading = "";
          if (response.data.auth) {
            if (response.data.secret.otpauth_url) {
              qrcode.toDataURL(
                response.data.secret.otpauth_url,
                (err, data) => {
                  if (data) {
                    this.showTwoFactorPanel = true;
                    this.title = "Two Factor Registration";
                    this.qr = data;
                  }
                }
              );
            }
          }
        })
        .catch((error) => {
          this.status = "";
          this.loading = "";
          this.status = error.response.data.message;
        });
    },
    validateToken: function () {
      const data = {
        email: this.$route.params.email,
        token: this.authCode,
        isnew: "1",
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/auth/validate-token`,
        data: data,
      })
        .then((response) => {
          if (response.data.validated) {
            this.$router.push("/");
          }
        })
        .catch((error) => {
          this.status = error.response.data.message;
        });
    },
  },
};
</script>
