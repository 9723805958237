<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="personneldetails"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Personnel Center</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              color="primary"
              class="mb-2"
              @click="
                $router.push({
                  name: 'PersonnelModal',
                  params: { id: '-1' },
                })
              "
            >
              New
            </v-btn>
          </div>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
              New
            </v-btn>
          </template>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
// import { CodeNode } from "source-list-map";

export default {
  data: () => ({
    modal: false,
    id: "",
    search: "",
    headers: [
      {
        text: "Emp Code",
        align: "center",
        sortable: true,
        value: "EmpId",
        width: "8%",
      },
      { text: "Name", value: "Name" },
      { text: "Surname", value: "Surname" },
      { text: "DOB", value: "DOB" },
      { text: "ID No", value: "IDNo" },
      { text: "Status", value: "Emp_Status" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "8%",
      },
    ],
    personneldetails: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      EmpId: "",
      Name: "",
      Surname: "",
      DOB: "",
      IDNo: "",
      PassNo: "",
      UIF: "",
      Tel_No: "",
      Cell_No: "",
      DOE: "",
      Occupation: "",
      Street: "",
      Area: "",
      City: "",
      Code: "",
      StreetP: "",
      AreaP: "",
      CityP: "",
      CodeP: "",
      EName1: "",
      ETel1: "",
      EName2: "",
      ETel2: "",
      BankName: "",
      BranchName: "",
      AccNo: "",
      BranchCode: "",
      Type: "",
      Method: "",
      Leave_Day: "",
      PCompany: "",
      GeneralDut: "",
      YOE: "",
      Path: "",
      Salary: "",
      PerHour: "",
      SType: "",
      YearEarn: "",
      TaxPaid: "",
      MonthPay: "",
      TravelAll: "",
      Gender: "",
      CitizenS: "",
      Age: "",
      Email: "",
      Leave_Rate: "",
      Emp_Status: "",
      Id_Types: "",
    },
    defaultItem: {
      id: "",
      EmpId: "",
      Name: "",
      Surname: "",
      DOB: "",
      IDNo: "",
      PassNo: "",
      UIF: "",
      Tel_No: "",
      Cell_No: "",
      DOE: "",
      Occupation: "",
      Street: "",
      Area: "",
      City: "",
      Code: "",
      StreetP: "",
      AreaP: "",
      CityP: "",
      CodeP: "",
      EName1: "",
      ETel1: "",
      EName2: "",
      ETel2: "",
      BankName: "",
      BranchName: "",
      AccNo: "",
      BranchCode: "",
      Type: "",
      Method: "",
      Leave_Day: "",
      PCompany: "",
      GeneralDut: "",
      YOE: "",
      Path: "",
      Salary: "",
      PerHour: "",
      SType: "",
      YearEarn: "",
      TaxPaid: "",
      MonthPay: "",
      TravelAll: "",
      Gender: "",
      CitizenS: "",
      Age: "",
      Email: "",
      Leave_Rate: "",
      Emp_Status: "",
      Id_Types: "",
    },
    acctype: [],
    acctypetest: [{ test: "admin" }, { test: "test" }],
  }),
  beforeMount: function () {
    let self = this;
    self.getPersonnel();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Employee" : "Edit Employee";
    },
    isRequired() {
      if (this.enableId == true) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {},
  methods: {
    getPersonnel: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/personnel`,
      })
        .then((response) => {
          this.personneldetails = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 401 ||
              error.response.status === 404
            ) {
              this.alerterror = true;
            }
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.personneldetails.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push({
        name: "PersonnelModal",
        params: { id: this.editedItem.id },
      });
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
      this.enableAddr = false;
    },
  },
};
</script>
