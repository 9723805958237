import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";

Vue.config.productionTip = true;

Vue.prototype.$http = axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

Vue.use(VueAxios, axios);

Vue.prototype.$hostname = Vue.config.productionTip
  ? "https://back-end-b.omsweb.co.za"
  : "http://localhost:3101";

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  //template: '<App/>',
  vuetify,
  render: (h) => h(App),
  components: { App },
});
