<template>
  <v-form ref="form">
    <v-toolbar flat>
      <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
      <v-divider class="mx-3" inset vertical left></v-divider>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-card>
          <v-card-text id="content">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-card outlined>
                    <v-toolbar color="primary" dense>
                      <v-toolbar-title>Product Info:</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-row no-gutters>
                            <v-col>
                              <v-autocomplete
                                v-model="editedItem.Inv_Type"
                                label="Inventory Type"
                                :items="inventorytypedb"
                                item-text="type"
                                item-value="type"
                                prepend-inner-icon="person"
                                :rules="[rules.required]"
                                auto-select-first
                                dense
                                outlined
                                class="pr-1"
                                :disabled="!isNewItem"
                                @change="setInventoryType"
                              ></v-autocomplete>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model="editedItem.Name"
                                prepend-inner-icon="inventory_2"
                                label="Name"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pl-1"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-autocomplete
                                v-model="itemA"
                                label="Item Type"
                                :items="itemtypesdb"
                                item-text="type"
                                item-value="type"
                                prepend-inner-icon="mdi-file-check"
                                :rules="[rules.required]"
                                auto-select-first
                                dense
                                outlined
                                class="pr-1"
                                :disabled="!isNewItem"
                                @change="getItemTypesCount"
                              >
                                <template v-slot:append-outer>
                                  <v-icon
                                    :disabled="!isNewItem"
                                    @click="dialog = !dialog"
                                    >mdi-plus-circle-outline</v-icon
                                  >
                                </template>
                              </v-autocomplete>
                            </v-col>

                            <v-col>
                              <v-text-field
                                label="Number"
                                type="number"
                                prepend-inner-icon="pin"
                                v-model="itemB"
                                :rules="[rules.required]"
                                outlined
                                dense
                                readonly
                                class="pl-1"
                                :disabled="!isNewItem"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-autocomplete
                                v-model="editedItem.Supplier"
                                label="Supplier"
                                :items="supplierdb"
                                item-text="Supplier"
                                item-value="Supplier"
                                prepend-inner-icon="mdi-account-network"
                                :rules="[rules.required]"
                                auto-select-first
                                dense
                                outlined
                                class="pr-1"
                                :readonly="!enableClient"
                              ></v-autocomplete>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Supplier Ref No"
                                v-model="editedItem.Supp_Ref_No"
                                prepend-inner-icon="mdi-comment-alert-outline"
                                outlined
                                dense
                                class="pl-1"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-text-field
                                label="Unit"
                                type="number"
                                prepend-inner-icon="mdi-weight"
                                v-model="editedItem.Unit"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pr-1"
                                @change="calcUnitTotal"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Qty"
                                type="number"
                                prepend-inner-icon="mdi-scale-balance"
                                v-model="editedItem.Qty"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pl-1"
                                @change="calcUnitTotal"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-text-field
                                label="Price P/U Excl"
                                type="number"
                                prepend-inner-icon="mdi-comment-alert-outline"
                                v-model="editedItem.Price_Unit"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pr-1"
                                @change="calcUnitTotal"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Price Qty"
                                type="number"
                                prepend-inner-icon="mdi-comment-alert-outline"
                                v-model="editedItem.Price_Qty"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pl-1"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-text-field
                                label="Markup"
                                type="number"
                                prepend-inner-icon="trending_up"
                                v-model="editedItem.Markup"
                                :rules="
                                  isService === true ? [rules.required] : []
                                "
                                outlined
                                dense
                                class="pr-1"
                                @change="calcMarkup"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Inc. Markup"
                                type="number"
                                prepend-inner-icon="mdi-chart-line"
                                v-model="incMarkup"
                                outlined
                                dense
                                class="pl-1"
                                readonly
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <!-- <v-row no-gutters>
                            <v-col>
                              <v-text-field
                                label="Vat"
                                type="number"
                                prepend-inner-icon="mdi-percent"
                                v-model="editedItem.Vat"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pr-1"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Inc. Vat"
                                type="number"
                                prepend-inner-icon="mdi-ticket-percent"
                                v-model="editedItem.Total_Inc"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pl-1"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row> -->

                          <v-row no-gutters>
                            <v-col>
                              <v-text-field
                                label="Reminder"
                                type="number"
                                prepend-inner-icon="production_quantity_limits"
                                v-model="editedItem.Min_Stock_Level"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pr-1"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Add Stock"
                                type="number"
                                prepend-inner-icon="add_shopping_cart"
                                v-model="addStock"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pl-1"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-text-field
                                label="Stock Level"
                                type="number"
                                prepend-inner-icon="shopping_cart_checkout"
                                v-model="editedItem.Stock_Level"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pr-1"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model="editedItem.Sales_Price"
                                prepend-inner-icon="mdi-equal"
                                label="Total"
                                :rules="[rules.required]"
                                outlined
                                dense
                                class="pl-1"
                                readonly
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-row no-gutters>
                            <v-col>
                              <v-text-field
                                v-model="editedInventoryList.Barcode"
                                prepend-inner-icon="mdi-barcode-scan"
                                label="Barcode"
                                :rules="
                                  isService === true ? [rules.required] : []
                                "
                                outlined
                                dense
                                class="pr-1"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model="editedInventoryList.Serial_No"
                                prepend-inner-icon="mdi-barcode"
                                label="Serial No"
                                :rules="
                                  isService === true ? [rules.required] : []
                                "
                                outlined
                                dense
                                class="pl-1 pr-1"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-autocomplete
                                v-model="editedInventoryList.Status"
                                label="Status"
                                :items="itemstatusdb"
                                item-text="Status"
                                item-value="Status"
                                prepend-inner-icon="check_circle"
                                :rules="
                                  isService === true ? [rules.required] : []
                                "
                                auto-select-first
                                dense
                                outlined
                                class="pl-1"
                                :disabled="!isService"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-menu
                                v-model="datePurchace"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedInventoryList.Date_Purchace"
                                    label="Date Purchace "
                                    prepend-inner-icon="mdi-calendar"
                                    :rules="
                                      isService === true ? [rules.required] : []
                                    "
                                    readonly
                                    outlined
                                    dense
                                    class="pr-1"
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!isService"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editedInventoryList.Date_Purchace"
                                  @input="datePurchace = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col>
                              <v-menu
                                v-model="dateOut"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedInventoryList.Date_Out"
                                    label="Date Out"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    class="pl-1"
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!isService"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editedInventoryList.Date_Out"
                                  @input="dateOut = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-text-field
                                v-model="editedInventoryList.Inv_Id"
                                prepend-inner-icon="mdi-file"
                                label="Invoice No"
                                :rules="
                                  isService === true ? [rules.required] : []
                                "
                                outlined
                                dense
                                class="pr-1"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model="editedInventoryList.Quote_Id"
                                prepend-inner-icon="mdi-file"
                                label="Quote No"
                                :rules="
                                  isService === true ? [rules.required] : []
                                "
                                outlined
                                dense
                                class="pl-1"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-text-field
                                v-model="editedInventoryList.PO_Id"
                                prepend-inner-icon="mdi-file"
                                label="Purchace No"
                                :rules="
                                  isService === true ? [rules.required] : []
                                "
                                outlined
                                dense
                                class="pr-1"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model="editedInventoryList.SO_Id"
                                prepend-inner-icon="mdi-file"
                                label="Sales No"
                                :rules="
                                  isService === true ? [rules.required] : []
                                "
                                outlined
                                dense
                                class="pl-1"
                                :disabled="!isService"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-col>
                              <v-autocomplete
                                v-model="editedInventoryList.Cust_Id"
                                label="Client"
                                :items="clientsdb"
                                item-text="Client"
                                item-value="id"
                                prepend-inner-icon="person"
                                :rules="
                                  isService === true ? [rules.required] : []
                                "
                                auto-select-first
                                dense
                                outlined
                                return-object
                                class="pr-1"
                                :disabled="!isService"
                              ></v-autocomplete>
                            </v-col>
                            <v-col>
                              <v-autocomplete
                                v-model="editedInventoryList.Emp_Id"
                                label="Employee"
                                :items="employeedb"
                                item-text="Employee"
                                item-value="id"
                                prepend-inner-icon="badge"
                                auto-select-first
                                dense
                                outlined
                                return-object
                                class="pl-1"
                                :readonly="!enableClient"
                                disabled
                              ></v-autocomplete>
                            </v-col>
                          </v-row>

                          <v-textarea
                            v-model="editedItem.Description"
                            label="Description"
                            prepend-inner-icon="description"
                            :rules="[rules.required]"
                            outlined
                            dense
                            counter
                            no-resize
                            rows="3"
                            maxlength="250"
                          ></v-textarea>
                          <v-textarea
                            v-model="editedItem.Display_Desc"
                            label="Display Description"
                            prepend-inner-icon="description"
                            :rules="[rules.required]"
                            outlined
                            dense
                            counter
                            no-resize
                            rows="2"
                            maxlength="100"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">New Item Type</span>
                </v-card-title>
                <v-card-text>
                  <v-form ref="itype" v-model="valid">
                    <!-- <v-container grid-list-md> -->
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="newItemType"
                        label="Item Type"
                        prepend-inner-icon="mdi-file-check"
                        :rules="[rules.required, rules.aphaOnly]"
                        maxlength="3"
                        clearable
                        dense
                        filled
                        outlined
                        counter
                        onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 96 && event.charCode <= 123"
                        @input="newItemType = newItemType.toUpperCase()"
                      ></v-text-field>
                    </v-flex>
                    <!-- </v-container> -->
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="mb-2" @click="close2"
                    >Close</v-btn
                  >
                  <v-btn color="primary" dark class="mb-2" @click="validate2"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-footer padless dense>
              <v-row justify="center" no-gutters>
                <v-btn
                  tile
                  :disabled="!enableCancel"
                  color="primary"
                  dark
                  class="mx-2"
                  @click="close"
                  >Cancel</v-btn
                >
                <v-btn
                  tile
                  :disabled="!enableSave"
                  color="primary"
                  dark
                  class="mb-2"
                  @click="validate"
                  >Save</v-btn
                >

                <v-btn
                  tile
                  :disabled="!enableClose"
                  color="primary"
                  dark
                  class="mx-2"
                  @click="close"
                  >Close</v-btn
                >
              </v-row>
            </v-footer>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { uid } from "uid";
export default {
  data: () => ({
    inventorytypedb: [],
    itemtypesdb: [],
    clientsdb: [],
    systemsetup: [],
    supplierdb: [],
    itemstatusdb: [],
    employeedb: [],
    editedInvoiceIndex: -1,
    menudoe: false,
    datePurchace: false,
    dateOut: false,
    isService: false,
    dialog: false,
    valid: true,
    Date_Purchace: "",
    Date_Out: "",
    id: "",
    status: "",
    itemA: "",
    itemB: "",
    employee: "",
    Client: "",
    addStock: "0",
    incMarkup: "",
    newItemType: "",
    enableCancel: true,
    enableSave: true,
    invSaved: false,
    enableNewItem: false,
    enableClient: true,
    enableClose: false,
    isNewItem: false,
    formValid: false,
    rules: {
      required: (value) => !!value || "Required.",
      alphaOnly: (v) => /^[a-zA-Z]/.test(v) || "Can not contain digits.",
      aphaOnly: (v) => {
        const aOnly = /[a-zA-Z]/;
        return aOnly.test(v) || "Can not contain digits.";
      },
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    editedItem: {
      id: "",
      Name: "",
      Description: "",
      Display_Desc: "",
      Supplier: "",
      Supp_Ref_No: "",
      Stock_Level: "0",
      Min_Stock_Level: "1",
      I_Type: "",
      Inv_Type: "",
      Price_Unit: "",
      Unit: "",
      Qty: "",
      Price_Qty: "",
      Markup: "",
      Sales_Price: "",
    },
    defaultItem: {
      id: "",
      Name: "",
      Description: "",
      Display_Desc: "",
      Supplier: "",
      Supp_Ref_No: "",
      Stock_Level: "0",
      Min_Stock_Level: "1",
      I_Type: "",
      Inv_Type: "",
      Price_Unit: "",
      Unit: "",
      Qty: "",
      Price_Qty: "",
      Markup: "",
      Sales_Price: "",
    },
    editedInventoryList: {
      I_Id: "",
      Status: "",
      Date_In: "",
      Date_Out: "",
      Cust_Id: "",
      Emp_Id: "",
      Quote_Id: "",
      SO_Id: "",
      PO_Id: "",
      Inv_Id: "",
      Barcode: "",
      Serial_No: "",
      Sup_Inv_No: "",
    },
    defaultInventoryList: {
      I_Id: "",
      Status: "",
      Date_In: "",
      Date_Out: "",
      Cust_Id: "",
      Emp_Id: "",
      Quote_Id: "",
      SO_Id: "",
      PO_Id: "",
      Inv_Id: "",
      Barcode: "",
      Serial_No: "",
      Sup_Inv_No: "",
    },
    formatter: new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  }),
  beforeMount: function () {
    let self = this;
    self.getSystemSetup();
    self.getInventoryTypes();
    self.getItemTypes();
    self.getItemStatus();
    self.getSupplier();
    self.getClients();
    if (this.$route.params.id > "-1") {
      self.getItemDetailsByItemId();
    } else {
      self.setDateNow();
      this.isNewItem = true;
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id === "-1"
        ? "New Item"
        : "Edit Item: " + this.$route.params.id;
    },

    getUserFull: function () {
      return localStorage.getItem("fullname");
    },
    getUserEmail: function () {
      return localStorage.getItem("useremail");
    },
  },
  methods: {
    getSystemSetup: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/systemsetup`,
      }).then((response) => {
        this.systemsetup = response.data;
        this.Company = response.data[0].Company;
      });
    },

    getInventoryTypes: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/inventorytypes`,
      }).then((response) => {
        this.inventorytypedb = response.data;
      });
    },
    getItemTypes: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/itemtypes`,
      }).then((response) => {
        this.itemtypesdb = response.data;
      });
    },
    getItemStatus: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/itemstatuses`,
      }).then((response) => {
        this.itemstatusdb = response.data;
      });
    },
    getItemTypesCount: function () {
      var I_Type = this.itemA;
      const data = {
        I_Type,
      };
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/inventories/count/${I_Type}`,
        data: data,
      }).then((response) => {
        var ItemCode = response.data.count;
        ItemCode++;
        if (ItemCode < 10) {
          ItemCode = "000" + ItemCode;
        } else if ((ItemCode >= 10) & (ItemCode < 100)) {
          ItemCode = "00" + ItemCode;
        } else if ((ItemCode >= 100) & (ItemCode < 1000)) {
          ItemCode = "0" + ItemCode;
        } else if ((ItemCode >= 1000) & (ItemCode < 10000)) {
          ItemCode = this.ItemCode;
        } else {
          alert("Maximumn account number for: " + I_Type);
        }
        this.itemB = ItemCode;
      });
    },
    getSupplier: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/suppliers`,
      }).then((response) => {
        this.supplierdb = response.data;
      });
    },

    getClients: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/clients`,
      }).then((response) => {
        this.clientsdb = response.data;
      });
    },

    getItemDetailsByItemId: function () {
      const data = {
        id: this.$route.params.id,
      };
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/inventories/${this.$route.params.id}`,
        data: data,
      }).then((response) => {
        this.editedItem.id = response.data.id;
        this.editedItem.Name = response.data.Name;
        this.editedItem.Description = response.data.Description;
        this.editedItem.Display_Desc = response.data.Display_Desc;
        this.editedItem.Supplier = response.data.Supplier;
        this.editedItem.Supp_Ref_No = response.data.Supp_Ref_No;
        this.editedItem.Stock_Level = response.data.Stock_Level;
        this.editedItem.Min_Stock_Level = response.data.Min_Stock_Level;
        this.editedItem.I_Type = response.data.I_Type;

        if (response.data.I_Type) {
          let itemType = response.data.I_Type;
          const itemTypeA = itemType.split("-");
          this.itemA = itemTypeA[0];
          this.itemB = itemTypeA[1];
        }

        this.editedItem.Inv_Type = response.data.Inv_Type;
        this.editedItem.Price_Unit = response.data.Price_Unit;
        this.editedItem.Unit = response.data.Unit;
        this.editedItem.Qty = response.data.Qty;
        this.editedItem.Price_Qty = response.data.Price_Qty;
        this.editedItem.Markup = response.data.Markup;
        this.editedItem.Sales_Price = response.data.Sales_Price;

        this.calcMarkup();
      });
    },

    setInventoryType: function () {
      if (this.editedItem.Inv_Type == "Service") {
        this.isService = false;
        this.editedItem.Unit = 1;
        this.editedItem.Qty = 1;
        this.editedItem.Markup = 0;
        this.incMarkup = "0.00";
        this.$refs.form.resetValidation();
      } else {
        this.isService = true;
        this.editedItem.Markup = "";
        this.incMarkup = "";
        this.$refs.form.resetValidation();
      }
    },

    postInventoryItem: function () {
      const data = {
        Name: this.editedItem.Name,
        Description: this.editedItem.Description,
        Display_Desc: this.editedItem.Display_Desc,
        Supplier: this.editedItem.Supplier,
        Supp_Ref_No: this.editedItem.Supp_Ref_No,
        Stock_Level: this.editedItem.Stock_Level,
        Min_Stock_Level: this.editedItem.Min_Stock_Level,
        I_Type: this.itemA + "-" + this.itemB,
        Inv_Type: this.editedItem.Inv_Type,
        Price_Unit: this.editedItem.Price_Unit,
        Unit: this.editedItem.Unit,
        Qty: this.editedItem.Qty,
        Price_Qty: this.editedItem.Price_Qty,
        Markup: this.editedItem.Markup,
        Sales_Price: this.editedItem.Sales_Price,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/inventories`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        this.editedItem.id = response.data.id;
      });
    },

    postItemType: function () {
      const data = {
        type: this.newItemType,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/itemtypes`,
        data: data,
      })
        .then((response) => {
          this.dbres = response.data;
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data.message);
          }
        });
    },

    updateItemDetails: function () {
      const data = {
        Name: this.editedItem.Name,
        Description: this.editedItem.Description,
        Display_Desc: this.editedItem.Display_Desc,
        Supplier: this.editedItem.Supplier,
        Supp_Ref_No: this.editedItem.Supp_Ref_No,
        Stock_Level: this.editedItem.Stock_Level,
        Min_Stock_Level: this.editedItem.Min_Stock_Level,
        I_Type: this.itemA + "-" + this.itemB,
        Inv_Type: this.editedItem.Inv_Type,
        Price_Unit: this.editedItem.Price_Unit,
        Unit: this.editedItem.Unit,
        Qty: this.editedItem.Qty,
        Price_Qty: this.editedItem.Price_Qty,
        Markup: this.editedItem.Markup,
        Sales_Price: this.editedItem.Sales_Price,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/Inventories/${this.editedItem.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        // this.deleteInvoice();
      });
    },

    setDateNow() {
      var today = new Date();
      // this.editedItem.Inv_Date = today.toISOString().substring(0, 10);
    },

    validate() {
      if (this.$refs.form.validate()) {
        if ((this.$route.params.id = "-1" && this.isNewItem)) {
          if (this.invSaved === false) {
            this.invSaved = !this.invSaved;
            this.enableClose = !this.enableClose;
            this.isNewItem = !this.isNewItem;
          }
          this.postInventoryItem();
        } else {
          this.updateItemDetails();
        }

        this.$refs.form.resetValidation();
      } else {
      }
    },

    validate2() {
      if (this.$refs.itype.validate()) {
        this.postItemType();
        this.$refs.itype.resetValidation();
      }
    },

    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    formatCurrency(num) {
      return this.formatter
        .format(num)
        .replace(/[^0-9\,.]/, "")
        .trim();
    },
    calcUnitTotal() {
      if (this.editedItem.Price_Unit) {
        let priceUnit = this.editedItem.Price_Unit;
        this.editedItem.Price_Qty = this.formatNumber(
          this.editedItem.Price_Unit / this.editedItem.Qty
        );

        this.editedItem.Price_Unit = this.formatNumber(priceUnit);
        this.calcTotal();
      }
    },
    calcMarkup() {
      if (this.editedItem.Markup && this.editedItem.Price_Unit) {
        let markup = "1." + this.editedItem.Markup;

        this.incMarkup = this.formatNumber(
          +this.editedItem.Price_Qty * +markup - +this.editedItem.Price_Qty
        );
        this.calcTotal();
      }
    },
    calcTotal() {
      if (this.incMarkup) {
        this.editedItem.Sales_Price = this.formatNumber(
          +this.incMarkup + +this.editedItem.Price_Qty
        );
      } else {
        this.editedItem.Sales_Price = this.formatNumber(
          +this.editedItem.Price_Qty
        );
      }
    },
    calculateSubTotals() {
      // return this.invoiceItemList.map((stotal) => {
      //   let sum = 0;
      //   sum += Number(stotal.Total);
      //   return sum;
      // });
    },
    close() {
      this.$router.push("/Inventory");
    },
    close2() {
      this.dialog = false;
      this.$refs.itype.resetValidation();
      this.getItemTypes();
    },
  },
};
</script>
