<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="earnings"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Earnings</v-toolbar-title>

          <v-divider class="mx-3" inset vertical left></v-divider>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-col xs="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.Earnings"
                        label="Earnings"
                        prepend-inner-icon="person"
                        :rules="[rules.required]"
                        clearable
                        dense
                        filled
                        outlined
                        counter
                      ></v-text-field>
                    </v-col>
                    <v-col xs="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.Rate"
                        label="Rate"
                        prepend-inner-icon="person"
                        :rules="[rules.required]"
                        clearable
                        dense
                        filled
                        outlined
                        counter
                      ></v-text-field>
                    </v-col>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="close"
                  >Cancel</v-btn
                >
                <v-btn color="primary" dark class="mb-2" @click="validate"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    show1: false,
    id: "",
    search: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
    },
    headers: [
      { text: "Earnings", align: "left", sortable: true, value: "Earnings" },
      { text: "Rate", value: "Rate" },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],
    earnings: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      Earnings: "",
      Rate: "",
    },
    defaultItem: {
      id: "",
      Earnings: "",
      Rate: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getEarnings();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Earnings" : "Edit Earnings";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {},
  methods: {
    getEarnings: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/earnings`,
      }).then((response) => (this.earnings = response.data));
    },
    postInsertEarnings: function () {
      const data = {
        Earnings: this.editedItem.Earnings,
        Rate: this.editedItem.Rate,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/earningsins`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postUpdateEarnings: function () {
      const data = {
        id: this.editedItem.id,
        Earnings: this.editedItem.Earnings,
        Rate: this.editedItem.Rate,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/earningsupd`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postDeleteEarnings: function () {
      const data = {
        id: this.id,
      };

      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/earningsdel`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.earnings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.id = item.id;
      const index = this.earnings.indexOf(item);
      if (confirm("Are you sure you want to delete this Earnings?")) {
        this.earnings.splice(index, 1);
        this.postDeleteEarnings();
      }
    },

    close() {
      this.dialog = false;
      // setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      // }, 300)
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.earnings[this.editedIndex], this.editedItem);
      } else {
        this.earnings.push(this.editedItem);
      }
      this.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.earnings[this.editedIndex], this.editedItem);
          this.postUpdateEarnings();
        } else {
          this.postInsertEarnings();
          this.earnings.push(this.editedItem);
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
  },
};
</script>
