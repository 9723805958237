<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-col cols="12" sm="4">
              <!-- <v-card outlined>
                <v-toolbar color="primary" dense> -->
              <!-- <v-toolbar-title></v-toolbar-title> -->
              <!-- </v-toolbar> -->
              <!-- <v-card-text> -->
              <v-flex>
                <v-text-field
                  v-model="editedItem.PayslipNo"
                  label="Payslip No"
                  outlined
                  clearable
                  dense
                  readonly
                  hide-details
                ></v-text-field>
              </v-flex>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-card outlined>
            <!-- <v-toolbar color="primary" dense>
              <v-toolbar-title>General Information:</v-toolbar-title>
            </v-toolbar> -->
            <v-card-title>General Information:</v-card-title>
            <v-card-text>
              <v-layout align-start row fill-height>
                <v-container fill-height grid-list-md text-xs-center>
                  <v-layout column>
                    <v-row>
                      <v-col xs="12" sm="12" md="4">
                        <v-flex>
                          <v-autocomplete
                            v-model="Emp_Id"
                            label="Employee"
                            :items="employeedb"
                            :item-text="
                              (item) => item.Name + ' ' + item.Surname
                            "
                            item-value="id"
                            prepend-inner-icon="person"
                            auto-select-first
                            dense
                            outlined
                            return-object
                            hide-details
                            tabindex="1"
                            @change="UpdateEmpDetails"
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.IDNo"
                            :rules="[rules.required]"
                            label="ID No"
                            prepend-inner-icon="badge"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="2"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Occupation"
                            :rules="[rules.required]"
                            label="Title"
                            prepend-inner-icon="mdi-clipboard-text"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="3"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.DOE"
                            :rules="[rules.required]"
                            label="D.O.E"
                            prepend-inner-icon="mdi-calendar-today"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="4"
                          ></v-text-field>
                        </v-flex>

                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Email"
                            :rules="[rules.required]"
                            label="Email"
                            prepend-inner-icon="email"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="5"
                          ></v-text-field>
                        </v-flex>
                      </v-col>

                      <v-col xs="12" sm="12" md="4">
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.BankName"
                            :rules="[rules.required]"
                            label="Bank"
                            prepend-inner-icon="mdi-bank"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="6"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.AccNo"
                            :rules="[rules.required]"
                            label="Acc No"
                            prepend-inner-icon="mdi-numeric"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="7"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.PerHour"
                            :rules="[rules.required]"
                            label="Per Hour"
                            prepend-inner-icon="mdi-clock-start"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="8"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Method"
                            :rules="[rules.required]"
                            label="Pay Method"
                            prepend-inner-icon="mdi-credit-card"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="9"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Company"
                            :rules="[rules.required]"
                            label="Leave"
                            prepend-inner-icon="mdi-calendar-range"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="10"
                          ></v-text-field>
                        </v-flex>
                      </v-col>

                      <v-col xs="12" sm="12" md="4">
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Company"
                            :rules="[rules.required]"
                            label="Pay Month"
                            prepend-inner-icon="mdi-calendar-multiple-check"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="11"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.EmpId"
                            :rules="[rules.required]"
                            label="Emp Code"
                            prepend-inner-icon="mdi-account-settings"
                            outlined
                            dense
                            readonly
                            hide-details
                            tabindex="12"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-checkbox
                            :label="`Payslip Issued`"
                            false-value="0"
                            true-value="1"
                            value="PayslipI"
                            id="PayslipI"
                            v-model="PaySlipIssued"
                            tabindex="13"
                            @change="checkPaySlipIssued"
                          >
                          </v-checkbox>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-container>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-container>
        <v-container>
          <v-row>
            <v-col xs="12" sm="12" md="12">
              <v-card outlined>
                <!-- <v-toolbar color="primary" dense>
                  <v-toolbar-title>Contributions:</v-toolbar-title>
                </v-toolbar> -->
                <v-card-title> Earnings: </v-card-title>
                <v-card-text>
                  <v-layout align-start row fill-height>
                    <v-container fill-height grid-list-md text-xs-center>
                      <v-layout column>
                        <v-row>
                          <v-col xs="12" sm="12" md="12">
                            <v-row>
                              <v-col xs="12" sm="12" md="6">
                                <v-flex>
                                  <v-text-field
                                    value="SALARY"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="14"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    value="TRAVEL ALLOWANCE"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="16"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    value="BONUS"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="18"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    label="Additional"
                                    outlined
                                    dense
                                    hide-details
                                    tabindex="20"
                                  ></v-text-field>
                                </v-flex>
                              </v-col>
                              <v-col xs="12" sm="12" md="6">
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Earn1"
                                    :rules="[rules.required]"
                                    label="Amount"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    hide-spin-buttons
                                    tabindex="15"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Earn2"
                                    label="Amount"
                                    type="number"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    hide-spin-buttons
                                    tabindex="17"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Earn3"
                                    label="Amount"
                                    type="number"
                                    outlined
                                    dense
                                    hide-details
                                    hide-spin-buttons
                                    tabindex="19"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Earn4"
                                    label="Amount"
                                    type="number"
                                    outlined
                                    dense
                                    hide-details
                                    hide-spin-buttons
                                    tabindex="21"
                                  ></v-text-field>
                                </v-flex>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col xs="12" sm="12" md="4">
                                <v-flex>
                                  <v-autocomplete
                                    v-model="editedItem.EDescr5"
                                    label="Overtime"
                                    :items="earningsdb"
                                    item-text="Earnings"
                                    item-value="Earnings"
                                    prepend-inner-icon="picture_in_picture"
                                    auto-select-first
                                    clearable
                                    dense
                                    filled
                                    outlined
                                    id="IdType"
                                    hide-details
                                    tabindex="22"
                                    @change="updateOvertime1"
                                  ></v-autocomplete>
                                </v-flex>
                                <v-flex>
                                  <v-autocomplete
                                    v-model="editedItem.EDescr6"
                                    label="Overtime"
                                    :items="earningsdb"
                                    item-text="Earnings"
                                    item-value="Earnings"
                                    prepend-inner-icon="picture_in_picture"
                                    auto-select-first
                                    clearable
                                    dense
                                    filled
                                    outlined
                                    hide-details
                                    id="IdType"
                                    tabindex="26"
                                    @change="updateOvertime2"
                                  ></v-autocomplete>
                                </v-flex>
                                <v-flex>
                                  <v-autocomplete
                                    v-model="editedItem.EDescr7"
                                    label="Overtime"
                                    :items="earningsdb"
                                    item-text="Earnings"
                                    item-value="Earnings"
                                    prepend-inner-icon="picture_in_picture"
                                    auto-select-first
                                    clearable
                                    dense
                                    filled
                                    outlined
                                    hide-details
                                    id="IdType"
                                    tabindex="30"
                                    @change="updateOvertime3"
                                  ></v-autocomplete>
                                </v-flex>
                                <v-flex>
                                  <v-autocomplete
                                    v-model="editedItem.EDescr8"
                                    label="Overtime"
                                    :items="earningsdb"
                                    item-text="Earnings"
                                    item-value="Earnings"
                                    prepend-inner-icon="picture_in_picture"
                                    auto-select-first
                                    clearable
                                    dense
                                    filled
                                    outlined
                                    hide-details
                                    id="IdType"
                                    tabindex="34"
                                    @change="updateOvertime4"
                                  ></v-autocomplete>
                                </v-flex>
                              </v-col>
                              <v-col xs="12" sm="12" md="2">
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Qty5"
                                    :rules="
                                      enableOvert1 === true
                                        ? [rules.required]
                                        : []
                                    "
                                    label="Qty"
                                    type="number"
                                    prepend-inner-icon="mdi-scale-balance"
                                    outlined
                                    dense
                                    hide-details
                                    tabindex="23"
                                    :readonly="!enableOvert1"
                                    @change="updateOvertime1"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Qty6"
                                    :rules="
                                      enableOvert2 === true
                                        ? [rules.required]
                                        : []
                                    "
                                    label="Qty"
                                    type="number"
                                    prepend-inner-icon="mdi-scale-balance"
                                    outlined
                                    dense
                                    hide-details
                                    tabindex="27"
                                    :readonly="!enableOvert2"
                                    @change="updateOvertime2"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Qty7"
                                    :rules="
                                      enableOvert3 === true
                                        ? [rules.required]
                                        : []
                                    "
                                    label="Qty"
                                    type="number"
                                    prepend-inner-icon="mdi-scale-balance"
                                    outlined
                                    dense
                                    hide-details
                                    tabindex="31"
                                    :readonly="!enableOvert3"
                                    @change="updateOvertime3"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Qty8"
                                    :rules="
                                      enableOvert4 === true
                                        ? [rules.required]
                                        : []
                                    "
                                    label="Qty"
                                    type="number"
                                    prepend-inner-icon="mdi-scale-balance"
                                    outlined
                                    dense
                                    hide-details
                                    tabindex="35"
                                    :readonly="!enableOvert4"
                                    @change="updateOvertime4"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-col>
                              <v-col xs="12" sm="12" md="3">
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Unit5"
                                    label="Rate"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="24"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Unit6"
                                    label="Rate"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="28"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Unit7"
                                    label="Rate"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="32"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Unit8"
                                    label="Rate"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="36"
                                  ></v-text-field>
                                </v-flex>
                              </v-col>
                              <v-col xs="12" sm="12" md="3">
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Earn5"
                                    label="Amount"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="25"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Earn6"
                                    label="Amount"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="29"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Earn7"
                                    label="Amount"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="33"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="editedItem.Earn8"
                                    label="Amount"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    tabindex="37"
                                  ></v-text-field>
                                </v-flex>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col xs="12" sm="12" md="12">
              <v-card outlined>
                <v-card-title>Deductions:</v-card-title>
                <v-card-text>
                  <v-layout align-start row fill-height>
                    <v-container fill-height grid-list-md text-xs-center>
                      <v-layout column>
                        <v-row>
                          <v-col xs="12" sm="12" md="6">
                            <v-flex>
                              <v-text-field
                                value="UIF"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="38"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                value="PAYE"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="40"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                value="MEDICAL AID"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="42"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                value="PENSION"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="44"
                              ></v-text-field>
                            </v-flex>
                          </v-col>
                          <v-col xs="12" sm="12" md="6">
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.Deduct1"
                                :rules="[rules.required]"
                                label="Amount"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="39"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.Deduct2"
                                :rules="[rules.required]"
                                label="Amount"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="41"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.Deduct3"
                                :rules="[rules.required]"
                                label="Amount"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="43"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.Deduct4"
                                :rules="[rules.required]"
                                label="Amount"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="45"
                              ></v-text-field>
                            </v-flex>
                          </v-col>
                        </v-row>
                      </v-layout>
                    </v-container>
                  </v-layout>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Type</th>
                          <th class="text-left">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in compddtsbd" :key="item.Type">
                          <td>
                            {{ item.Type }}
                          </td>
                          <td>
                            {{ item.Amount }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col xs="12" sm="12" md="3">
              <v-card outlined style="height: 315px">
                <v-card-title>Year To Date:</v-card-title>
                <!-- <v-toolbar color="primary" dense>
                  <v-toolbar-title>Year To Date:</v-toolbar-title>
                </v-toolbar> -->
                <v-card-text>
                  <v-layout align-start row fill-height>
                    <v-container fill-height grid-list-md text-xs-center>
                      <v-layout column>
                        <v-row>
                          <v-col xs="12" sm="12" md="12">
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.EmpId"
                                :rules="[rules.required]"
                                label="Taxable Earnings"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="46"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.EmpId"
                                :rules="[rules.required]"
                                label="Tax Paid"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="47"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.EmpId"
                                :rules="[rules.required]"
                                label="Pension"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="48"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.EmpId"
                                :rules="[rules.required]"
                                label="Medical Aid"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="49"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.Uif"
                                :rules="[rules.required]"
                                label="UIF"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="50"
                              ></v-text-field>
                            </v-flex>
                          </v-col>
                        </v-row>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="3">
              <v-card outlined style="height: 315px">
                <v-card-title>Month To Date:</v-card-title>
                <!-- <v-toolbar color="primary" dense>
                  <v-toolbar-title>Month To Date:</v-toolbar-title>
                </v-toolbar> -->
                <v-card-text>
                  <v-layout align-start row fill-height>
                    <v-container fill-height grid-list-md text-xs-center>
                      <v-layout column>
                        <v-row>
                          <v-col xs="12" sm="12" md="12">
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.GrossPay"
                                :rules="[rules.required]"
                                label="Gross Pay"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="51"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.TotalDeduct"
                                :rules="[rules.required]"
                                label="Total Deductions"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="52"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.NettPay"
                                :rules="[rules.required]"
                                label="Nett Pay"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="53"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.TaxPerks"
                                :rules="[rules.required]"
                                label="Taxable Perks"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="54"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                v-model="editedItem.CompanyCon"
                                :rules="[rules.required]"
                                label="Company Contributions"
                                outlined
                                dense
                                readonly
                                hide-details
                                tabindex="55"
                              ></v-text-field>
                            </v-flex>
                          </v-col>
                        </v-row>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="6">
              <v-row>
                <v-col xs="12" sm="12" md="12">
                  <v-card outlined style="height: 240px">
                    <v-card-title>Contributions:</v-card-title>
                    <v-card-text>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Type</th>
                              <th class="text-left">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in compctbsbd" :key="item.Type">
                              <td>
                                {{ item.Type }}
                              </td>
                              <td>
                                {{ item.Amount }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col xs="12" sm="12" md="6">
                  <v-card outlined style="height: 50px">
                    <v-card-text>
                      <v-layout align-start row fill-height>
                        <v-layout column>
                          <v-flex>
                            <v-card>
                              <v-flex>
                                <v-text-field
                                  v-model="editedItem.EmpId"
                                  :rules="[rules.required]"
                                  label="Nett Pay"
                                  outlined
                                  dense
                                  readonly
                                  hide-details
                                  tabindex="56"
                                ></v-text-field>
                              </v-flex>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- <v-column cols="12" sm="4"> </v-column> -->
        </v-container>
        <v-spacer></v-spacer>
        <v-footer padless dense>
          <v-row justify="center" no-gutters>
            <v-btn
              tile
              :disabled="!enableCancel"
              color="primary"
              dark
              class="mx-2"
              tabindex="57"
              >Cancel</v-btn
            >
            <v-btn
              tile
              :disabled="!enableSave"
              color="primary"
              dark
              class="mb-2"
              tabindex="58"
              >Save</v-btn
            >
            <v-btn
              tile
              :disabled="!enabledPrint"
              color="primary"
              dark
              class="mx-2"
              tabindex="59"
              >Print</v-btn
            >
            <v-btn
              tile
              :disabled="!enableClose"
              color="primary"
              dark
              class="mb-2"
              tabindex="60"
              >Close</v-btn
            >
          </v-row>
        </v-footer>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { uid } from "uid";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
export default {
  data: () => ({
    employeedb: [],
    sarsdb: [],
    compctbsbd: [],
    compddtsbd: [],
    earningsdb: [],
    enabledPrint: false,
    enableCancel: true,
    enableSave: true,
    invoiceSaved: false,
    enableNewItem: false,
    enableClient: true,
    enableClose: false,
    enableOvert1: false,
    enableOvert2: false,
    enableOvert3: false,
    enableOvert4: false,
    isNewInvoice: false,
    Emp_Id: "",
    PaySlipIssued: false,
    RatesOfTax1: 0,
    RatesOfTax2: 0,
    RatesOfTax3: 0,
    RatesOfTax4: 0,
    RatesOfTax5: 0,
    RatesOfTax6: 0,
    RatesOfTaxP1: 0,
    RatesOfTaxP2: 0,
    RatesOfTaxP3: 0,
    RatesOfTaxP4: 0,
    RatesOfTaxP5: 0,
    RatesOfTaxP6: 0,
    RatesOfTaxP7: 0,
    TaxIncome1: 0,
    TaxIncome2: 0,
    TaxIncome3: 0,
    TaxIncome4: 0,
    TaxIncome5: 0,
    TaxIncome6: 0,
    TaxRebate: 0,
    TaxThresH: 0,
    Year: 0,
    TaxRates: [],
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 10 || "Min 10 characters",
      minId: (v) => v.length >= 13 || "Min 13 characters",
      requiredId: (value) => {
        if (value == true) {
          return true;
        }
        if (value == false) {
          return false;
        }
      },
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
      counteraddMax: (value) => value.length <= 4 || "Max 4 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    editedItem: {
      id: "",
      PayslipNo: "",
      EmpId: "",
      EDescr1: "SALARY",
      Earn1: "",
      EDescr2: "TRAVEL ALLOWANCE",
      Earn2: "",
      EDescr3: "BONUS",
      Earn3: "",
      EDescr4: "",
      Earn4: "",
      EDescr5: "",
      Earn5: "",
      Qty5: "",
      Unit5: "",
      EDescr6: "",
      Earn6: "",
      Qty6: "",
      Unit6: "",
      EDescr7: "",
      Earn7: "",
      Qty7: "",
      Unit7: "",
      EDescr8: "",
      Earn8: "",
      Qty8: "",
      Unit8: "",
      DDescr1: "UIF",
      Deduct1: "",
      DDescr2: "PAYE",
      Deduct2: "",
      DDescr3: "MEDICAL AID",
      Deduct3: "",
      DDescr4: "PENSION",
      Deduct4: "",
      DDescr5: "",
      Deduct5: "",
      DDescr6: "",
      Deduct6: "",
      DDescr7: "",
      Deduct7: "",
      DDescr8: "",
      Deduct8: "",
      TaxEarn: "",
      TaxPaid: "",
      Pension: "",
      Medical_Aid: "",
      Uif: "",
      GrossPay: "",
      TotalDeduct: "",
      TaxPerks: "",
      CompanyCon: "",
      NettPay: "",
      MonthPay: "",
      Leave_Day: "",
      Emp_Name: "",
      Emp_Surname: "",
      IDNo: "",
      PassNo: "",
      DOE: "",
      Occupation: "",
      BankName: "",
      AccNo: "",
      Method: "",
      Email: "",
      PerHour: "",
    },
    defaultItem: {
      id: "",
      PayslipNo: "",
      EmpId: "",
      EDescr1: "SALARY",
      Earn1: "",
      EDescr2: "TRAVEL ALLOWANCE",
      Earn2: "",
      EDescr3: "BONUS",
      Earn3: "",
      EDescr4: "",
      Earn4: "",
      EDescr5: "",
      Earn5: "",
      Qty5: "",
      Unit5: "",
      EDescr6: "",
      Earn6: "",
      Qty6: "",
      Unit6: "",
      EDescr7: "",
      Earn7: "",
      Qty7: "",
      Unit7: "",
      EDescr8: "",
      Earn8: "",
      Qty8: "",
      Unit8: "",
      DDescr1: "UIF",
      Deduct1: "",
      DDescr2: "PAYE",
      Deduct2: "",
      DDescr3: "MEDICAL AID",
      Deduct3: "",
      DDescr4: "PENSION",
      Deduct4: "",
      DDescr5: "",
      Deduct5: "",
      DDescr6: "",
      Deduct6: "",
      DDescr7: "",
      Deduct7: "",
      DDescr8: "",
      Deduct8: "",
      TaxEarn: "",
      TaxPaid: "",
      Pension: "",
      Medical_Aid: "",
      Uif: "",
      GrossPay: "",
      TotalDeduct: "",
      TaxPerks: "",
      CompanyCon: "",
      NettPay: "",
      MonthPay: "",
      Leave_Day: "",
      Emp_Name: "",
      Emp_Surname: "",
      IDNo: "",
      PassNo: "",
      UIF: "",
      DOE: "",
      Occupation: "",
      BankName: "",
      AccNo: "",
      Method: "",
      Email: "",
      PerHour: "",
    },
    formatter: new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  }),
  beforeMount: function () {
    let self = this;
    self.getEmployees();
    self.getEarnings();
    self.getCurrentYear();
  },
  computed: {
    formTitle() {
      return this.$route.params.id === "-1"
        ? "New Payslip"
        : "Edit Payslip: " + this.$route.params.id;
    },
  },
  methods: {
    async getEmployees() {
      await this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/personnel`,
      }).then((response) => {
        this.employeedb = response.data;
      });
    },
    getCurrentYear: function () {
      const d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth();
      if (month >= 2) {
        year++;
      }
      this.getSars(year);
    },
    async getSars(cyear) {
      let Year = cyear;
      await this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/sars/year/${Year}`,
      }).then((response) => {
        this.sarsdb = response.data;
        console.log(this.sarsdb);
        this.RatesOfTax1 = this.sarsdb.RatesOfTax1;
        this.RatesOfTax2 = this.sarsdb.RatesOfTax2;
        this.RatesOfTax3 = this.sarsdb.RatesOfTax3;
        this.RatesOfTax4 = this.sarsdb.RatesOfTax4;
        this.RatesOfTax5 = this.sarsdb.RatesOfTax5;
        this.RatesOfTax6 = this.sarsdb.RatesOfTax6;
        this.RatesOfTaxP1 = this.sarsdb.RatesOfTaxP1;
        this.RatesOfTaxP2 = this.sarsdb.RatesOfTaxP2;
        this.RatesOfTaxP3 = this.sarsdb.RatesOfTaxP3;
        this.RatesOfTaxP4 = this.sarsdb.RatesOfTaxP4;
        this.RatesOfTaxP5 = this.sarsdb.RatesOfTaxP5;
        this.RatesOfTaxP6 = this.sarsdb.RatesOfTaxP6;
        this.RatesOfTaxP7 = this.sarsdb.RatesOfTaxP7;
        this.TaxIncome1 = this.sarsdb.TaxIncome1;
        this.TaxIncome2 = this.sarsdb.TaxIncome2;
        this.TaxIncome3 = this.sarsdb.TaxIncome3;
        this.TaxIncome4 = this.sarsdb.TaxIncome4;
        this.TaxIncome5 = this.sarsdb.TaxIncome5;
        this.TaxIncome6 = this.sarsdb.TaxIncome6;
        this.TaxRebate = this.sarsdb.TaxRebate;
        this.TaxThresH = this.sarsdb.TaxThresH;
        this.Year = this.sarsdb.Year;
      });
    },
    async getEarnings() {
      await this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/earnings`,
      }).then((response) => {
        this.earningsdb = response.data;
      });
    },
    async UpdateEmpDetails() {
      var employeedbIndex = this.employeedb.findIndex(
        (data) => data === this.Emp_Id
      );

      this.editedItem.EmpId = this.employeedb[employeedbIndex].EmpId;
      this.editedItem.Name = this.employeedb[employeedbIndex].Name;
      this.editedItem.Surname = this.employeedb[employeedbIndex].Surname;
      this.editedItem.IDNo = this.employeedb[employeedbIndex].IDNo;
      this.editedItem.PassNo = this.employeedb[employeedbIndex].PassNo;
      this.editedItem.Uif = this.employeedb[employeedbIndex].UIF;

      this.editedItem.DOE = this.employeedb[employeedbIndex].DOE;
      this.editedItem.Occupation = this.employeedb[employeedbIndex].Occupation;
      this.editedItem.BankName = this.employeedb[employeedbIndex].BankName;
      this.editedItem.AccNo = this.employeedb[employeedbIndex].AccNo;

      this.editedItem.Method = this.employeedb[employeedbIndex].Method;
      this.editedItem.PerHour = this.employeedb[employeedbIndex].PerHour;

      this.editedItem.Email = this.employeedb[employeedbIndex].Email;

      this.editedItem.Earn1 = this.formatNumber(
        this.employeedb[employeedbIndex].Salary
      );

      this.getEmployeesPaySlipNo();
      await this.getContributions(this.editedItem.EmpId);
      await this.getDeductions(this.editedItem.EmpId);
      await this.calcEarnings();
      this.calcTax();
    },
    checkPaySlipIssued() {
      this.$nextTick(() => {
        let elt = document.getElementById("PayslipI");
        // if (elt.checked) {
        //   this.enabledVat = true;
        // } else {
        //   this.enabledVat = false;
        // }
      });
    },
    getEmployeesPaySlipNo() {
      var EmpId = this.editedItem.EmpId;
      const data = {
        EmpId,
      };
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/payslips/empid/${EmpId}`,
        data: data,
      }).then((response) => {
        var EmpPaySlipNo = response.data[0];

        if (!EmpPaySlipNo) {
          EmpPaySlipNo = 0;
        }
        EmpPaySlipNo++;
        this.editedItem.PayslipNo = EmpPaySlipNo;
      });
    },
    async getContributions(EmpId) {
      const data = {
        EmpId: EmpId,
      };
      await this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/compctbs/empid/${EmpId}`,
        data: data,
      }).then((response) => {
        this.compctbsbd = response.data;
      });
    },
    async getDeductions(EmpId) {
      const data = {
        EmpId: EmpId,
      };
      await this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/compddts/empid/${EmpId}`,
        data: data,
      }).then((response) => {
        this.compddtsbd = response.data;
      });
    },
    async updateOvertime1() {
      if (this.editedItem.EDescr5) {
        this.enableOvert1 = true;

        var earningsdbIndex = this.earningsdb.findIndex(
          (data) => data.Earnings === this.editedItem.EDescr5
        );

        let Qty5 = this.editedItem.Qty5;
        let Rate5 = this.editedItem.Unit5;
        let Earn5 = this.editedItem.Earn5;
        let PerH = this.editedItem.PerHour;

        if (earningsdbIndex > -1) {
          if (Qty5 && PerH) {
            Rate5 = PerH * this.earningsdb[earningsdbIndex].Rate;
            this.editedItem.Unit5 = this.formatNumber(Rate5);
            Earn5 = Qty5 * Rate5;
            this.editedItem.Earn5 = this.formatNumber(Earn5);
            await this.calcEarnings();
            this.calcTax();
          }
        } else {
          alert("Unable to find Rate");
        }
      } else {
        this.enableOvert1 = false;
      }
    },
    async updateOvertime2() {
      if (this.editedItem.EDescr6) {
        this.enableOvert2 = true;

        var earningsdbIndex = this.earningsdb.findIndex(
          (data) => data.Earnings === this.editedItem.EDescr6
        );

        let Qty6 = this.editedItem.Qty6;
        let Rate6 = this.editedItem.Unit6;
        let Earn6 = this.editedItem.Earn6;
        let PerH = this.editedItem.PerHour;

        if (earningsdbIndex > -1) {
          if (Qty6 && PerH) {
            Rate6 = PerH * this.earningsdb[earningsdbIndex].Rate;
            this.editedItem.Unit6 = this.formatNumber(Rate6);
            Earn6 = Qty6 * Rate6;
            this.editedItem.Earn6 = this.formatNumber(Earn6);
            await this.calcEarnings();
            this.calcTax();
          }
        } else {
          alert("Unable to find Rate");
        }
      } else {
        this.enableOvert2 = false;
      }
    },

    async updateOvertime3() {
      if (this.editedItem.EDescr7) {
        this.enableOvert3 = true;

        var earningsdbIndex = this.earningsdb.findIndex(
          (data) => data.Earnings === this.editedItem.EDescr7
        );

        let Qty7 = this.editedItem.Qty7;
        let Rate7 = this.editedItem.Unit7;
        let Earn7 = this.editedItem.Earn7;
        let PerH = this.editedItem.PerHour;

        if (earningsdbIndex > -1) {
          if (Qty7 && PerH) {
            Rate7 = PerH * this.earningsdb[earningsdbIndex].Rate;
            this.editedItem.Unit7 = this.formatNumber(Rate7);
            Earn7 = Qty7 * Rate7;
            this.editedItem.Earn7 = this.formatNumber(Earn7);
            await this.calcEarnings();
            this.calcTax();
          }
        } else {
          alert("Unable to find Rate");
        }
      } else {
        this.enableOvert3 = false;
      }
    },

    async updateOvertime4() {
      if (this.editedItem.EDescr8) {
        this.enableOvert4 = true;

        var earningsdbIndex = this.earningsdb.findIndex(
          (data) => data.Earnings === this.editedItem.EDescr8
        );

        let Qty8 = this.editedItem.Qty8;
        let Rate8 = this.editedItem.Unit8;
        let Earn8 = this.editedItem.Earn8;
        let PerH = this.editedItem.PerHour;

        if (earningsdbIndex > -1) {
          if (Qty8 && PerH) {
            Rate8 = PerH * this.earningsdb[earningsdbIndex].Rate;
            this.editedItem.Unit8 = this.formatNumber(Rate8);
            Earn8 = Qty8 * Rate8;
            this.editedItem.Earn8 = this.formatNumber(Earn8);
            await this.calcEarnings();
            this.calcTax();
          }
        } else {
          alert("Unable to find Rate");
        }
      } else {
        this.enableOvert4 = false;
      }
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    formatCurrency(num) {
      return this.formatter
        .format(num)
        .replace(/[^0-9\,.]/, "")
        .trim();
    },
    async calcEarnings() {
      let UIF = 0;

      let Earn1 = +this.editedItem.Earn1 || 0;
      let Earn2 = +this.editedItem.Earn2 || 0;
      let Earn3 = +this.editedItem.Earn3 || 0;
      let Earn4 = +this.editedItem.Earn4 || 0;
      let Earn5 = +this.editedItem.Earn5 || 0;
      let Earn6 = +this.editedItem.Earn6 || 0;
      let Earn7 = +this.editedItem.Earn7 || 0;
      let Earn8 = +this.editedItem.Earn8 || 0;

      const sum = Earn1 + Earn2 + Earn3 + Earn4 + Earn5 + Earn6 + Earn7 + Earn8;

      if (sum > 17712) {
        UIF = 17712 / 100;
      } else {
        UIF = sum / 100;
      }
      this.editedItem.Deduct1 = UIF;
      this.editedItem.GrossPay = this.formatNumber(sum);
    },
    calcDeductions: function () {
      //////
    },
    calcTax() {
      const grossPay = +this.editedItem.GrossPay;
      let RateOfTax = 0;
      let RateOfTaxP = 0;
      let RateofTaxT = 0;
      let TaxTotal = 0;
      let grossPayYear = grossPay * 12;

      if (grossPayYear < this.TaxIncome1 && grossPay > this.TaxThresH) {
        RateOfTaxP = this.RatesOfTaxP1;
        TaxTotal = ((grossPayYear * RateOfTaxP) / 100 - this.TaxRebate) / 12;
      } else if (
        grossPayYear > this.TaxIncome1 &&
        grossPayYear <= this.TaxIncome2
      ) {
        this.TaxRates = this.getRates("1", "1", "2");
        TaxTotal = grossPayYear - RateofTaxT;
        TaxTotal =
          ((TaxTotal * +this.TaxRates.RateOfTaxP) / 100 +
            +this.TaxRates.RateOfTax -
            this.TaxRebate) /
          12;
      } else if (
        grossPayYear > this.TaxIncome2 &&
        grossPayYear <= this.TaxIncome3
      ) {
        this.TaxRates = this.getRates("2", "2", "3");
        TaxTotal = grossPayYear - this.TaxRates.RateofTaxT;
        TaxTotal =
          ((TaxTotal * +this.TaxRates.RateOfTaxP) / 100 +
            +this.TaxRates.RateOfTax -
            this.TaxRebate) /
          12;
      } else if (
        grossPayYear > this.TaxIncome3 &&
        grossPayYear <= this.TaxIncome4
      ) {
        this.TaxRates = this.getRates("3", "3", "4");
        TaxTotal = grossPayYear - this.TaxRates.RateofTaxT;
        TaxTotal =
          ((TaxTotal * +this.TaxRates.RateOfTaxP) / 100 +
            +this.TaxRates.RateOfTax -
            this.TaxRebate) /
          12;
      } else if (
        grossPayYear > this.TaxIncome4 &&
        grossPayYear <= this.TaxIncome5
      ) {
        this.TaxRates = this.getRates("4", "4", "5");
        TaxTotal = grossPayYear - this.TaxRates.RateofTaxT;
        TaxTotal =
          ((TaxTotal * +this.TaxRates.RateOfTaxP) / 100 +
            +this.TaxRates.RateOfTax -
            this.TaxRebate) /
          12;
      } else if (
        grossPayYear > this.TaxIncome5 &&
        grossPayYear <= this.TaxIncome6
      ) {
        //this.TaxRates = this.getRates("0", "0", "1");
        this.TaxRates = this.getRates("5", "5", "6");
        console.log(this.TaxRates);

        TaxTotal = grossPayYear - this.TaxRates.RateofTaxT;
        TaxTotal =
          ((TaxTotal * +this.TaxRates.RateOfTaxP) / 100 +
            +this.TaxRates.RateOfTax -
            this.TaxRebate) /
          12;
      } else if (grossPayYear > this.TaxIncome6) {
        this.TaxRates = this.getRates("6", "6", "7");

        TaxTotal = grossPayYear - this.TaxRates.RateofTaxT;
        TaxTotal =
          ((TaxTotal * +this.TaxRates.RateOfTaxP) / 100 +
            +this.TaxRates.RateOfTax -
            this.TaxRebate) /
          12;
      }
      this.editedItem.Deduct2 = this.formatNumber(TaxTotal);
    },
    getRates: function (Num1, Num2, Num3) {
      let nVar1 = 0;
      let nVar2 = 0;
      if (Num1 != "0" && Num2 != "0") {
        let RateofTaxT = "this.TaxIncome" + Num1;
        console.log("T " + RateofTaxT);
        nVar1 = eval(RateofTaxT);
        console.log("V " + nVar1);
        let RateOfTax = "this.RatesOfTax" + Num2;
        nVar2 = eval(RateOfTax);
      } else {
        console.log("1");
      }
      // let RateofTaxT = "this.TaxIncome" + Num1;
      // let nVar1 = eval(RateofTaxT);
      // let RateOfTax = "this.RatesOfTax" + Num2;
      // let nVar2 = eval(RateOfTax);
      let RateOfTaxP = "this.RatesOfTaxP" + Num3;
      let nVar3 = eval(RateOfTaxP);

      return { RateofTaxT: nVar1, RateOfTax: nVar2, RateOfTaxP: nVar3 };
    },
  },
};
</script>
